import './Webinar.css';

const Webinar = ({ item }) => {
	return (
		<div className="webinar-container shadow-sm">
			<div className="webinar-date">
				<i className="fa-regular fa-calendar-days" />
				<h5>{item.startDateFormatted}</h5>
			</div>
			<hr />
			<div className="webinar-title">
				<h4>{item.name}</h4>
			</div>
			{item.description && (
				<div className="webinar-description">
					<small className="text-muted">{item.description}</small>
				</div>
			)}
			<div className="webinar-actions">
				<div className="webinar-time">
					<i className="fa-regular fa-clock" />
					<span>{item.startTimeFormatted}</span>
				</div>
				<div className="webinar-link">
					<a
						href={item.registrationUrl}
						target="_blank"
						className="btn btn-primary"
					>
						Register Now
					</a>
				</div>
			</div>
		</div>
	);
};

export default Webinar;
