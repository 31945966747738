import axios from 'axios';

// const apiHost = 'https://localhost:7113/';
const apiHost = 'https://webinarsapi.fortunebuilders.com/';

export const GetWebinarsList = async () => {
	const response = await axios
		.get(apiHost + 'webinars', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data;
};

export const GetSpecificWebinarsList = async () => {
	const response = await axios
		.get(apiHost + 'webinars/specific-days', null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { d: { IsSuccessful: false, Message: err } } };
		});

	return response.data;
};
