import { Fragment, useEffect, useState } from 'react';
import { GetSpecificWebinarsList, GetWebinarsList } from '../../Services';
import Spinner from '../spinner/Spinner';
import WarningMessage from '../warningMessage/WarningMessage';
import Webinar from './webinar/Webinar';
import './WebinarsList.css';

const WebinarsList = ({ specificWebinars = false }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ webinars, setWebinars ] = useState();
	const [ isError, setIsError ] = useState(false);

	useEffect(() => {
		getWebinarsList();
	}, []);

	const getWebinarsList = async () => {
		let response;

		if (!specificWebinars) {
			response = await GetWebinarsList();
		} else {
			response = await GetSpecificWebinarsList();
		}

		console.log(response);

		if (response && response.length > 0) {
			setWebinars(response);
		} else {
			setIsError(true);
		}

		setIsLoading(false);
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return (
			<WarningMessage message="There are currently no webinars available. Please check back later." />
		);
	}

	return (
		<Fragment>
			{webinars &&
			webinars.length > 0 && (
				<Fragment>
					<div className="webinars-container">
						{webinars.map((webinar) => (
							<Webinar key={webinar.id} item={webinar} />
						))}
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default WebinarsList;
