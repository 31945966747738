import { Route, Routes } from 'react-router-dom';
import './App.css';
import WebinarsList from './components/webinarsList/WebinarsList';

function App() {
	return (
		<div className="App">
			<div className="container-fluid">
				<Routes>
					<Route
						path="/specific-days"
						element={<WebinarsList specificWebinars />}
					/>
					<Route path="/" element={<WebinarsList />} />
				</Routes>
				{/* <WebinarsList /> */}
			</div>
		</div>
	);
}

export default App;
